*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
}

:root{
    --bg-color: rgb(45, 46, 50);
    --second-bg-color: #1e1f22;
    --text-color: #f5f5f5;
    --main-color: #61f5ab;
}

body{
    background-color: var(--bg-color);
    color: var(--text-color);
    
}