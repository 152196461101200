@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Trispace:wght@100;200;300;400;500;600;700;800&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
}

:root{
    --bg-color: #2d2e32;
    --second-bg-color: #1e1f22;
    --text-color: #f5f5f5;
    --main-color: #61f5ab;
}

.navbar-container{
    display: flex;
    top: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    background-color: #2d2e32e0;
    padding: 2rem 0;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.navbar-logo a{
    font-size: 30px;
    font-family: 'Trispace', sans-serif;
}

.firstname{

    font-weight: 900;
}

.lastname{
    font-weight: 200;
}

.navbar-menu{
    display: flex;
}

.navbar-menu ul{
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 15px;
}

.navbar-menu li{
    margin: 0px 15px;
}


.navbar-social ul{
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 12px;
    font-weight: 100;
}

.navbar-social li{
    display: flex;
    align-items: center;
    margin: 0px 10px;
}

.active{
    color: var(--main-color);
}


.navbar-logo a,
.navbar-menu li a,
.navbar-social a{
    text-decoration: none;
    color: #f5f5f5;
    align-items: center;
}

.navbar-menu li a:hover,
.navbar-social a:hover{
    color: #61f5ab;
    cursor: pointer;
}

.navbar-social a{
    margin-right: 20px;
}

.navbar-social img{
    width: 20px;
    margin-inline: -5px;
    margin-bottom: -3px;
}

.toggle-button{
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
}

.toggle-button .bar{
    height: 3px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.contact-me{
    display: none;
}

.contact-me button{
    padding: 10px 10px;
    font-size: 14;
    font-family: 'Space Mono', monospace;
    background-color: #2d2e32;
    border-radius: 10px;
    border: solid 3px #f5f5f5;
    color: #f5f5f5;
    font-size: 20px;
}

.contact-me button:hover{
    background-color: #f5f5f5;
    color: rgb(45, 46, 50);
    cursor: pointer;
}

#menu-icon{
    font-size: 3.5rem;
    color: var(--text-color);
    display: none;
}

@media screen and (max-width: 1100px) {
    html{
        font-size: 80%;
    }

    section{
        padding: 2rem 3% 2rem;
        margin: 0;
    }
    
    #menu-icon{
        display: block;
        margin-right: 1rem;
    }

    .navbar-container{
        justify-content: space-between;
        font-size: 20px;
    }

    .navbar-logo{
        margin: 0 2rem;
    }

    .navbar-menu{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 1 3%;
        background-color: var(--second-bg-color);
        border-top: .1rem solid rgba(0, 0, 0, .2);
        flex-direction: column;
        align-items: center;
        transform: translateX(100%);
        transition: transform 1s ease-in-out;  
    }

    .navbar-menu ul{
        
        font-size: 20px;
        margin: 2rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 4rem;
    }

    .navbar-menu ul li{
        margin: 1rem 0;
    }

    .navbar-social{
        position: absolute;
        top: 430%;
        left: 0;
        width: 100%;
        padding: 2rem 3%;
        padding-bottom: 60vh;
        background-color: var(--second-bg-color);
        display: flex;
        justify-content: center;
        padding-left: 3rem;
        transform: translateX(100%);
        transition: transform 1s ease-in-out;

    }

   

    .active{
        transform: translateX(0%);
    }

    .hero{
        flex-direction: column;
    }
    .hero .img-container img{
        width: 70vw;
    }

    .about #about{
        top: -1100px;
    }

    .about{
        flex-direction: column-reverse;
    }

    .about .about-img img {
        margin-top: 4rem;
        width: 70vw;
    }

    .about .about-content{
        width: 70vw;
        
    }
    .portfolio #portfolio{
        top: -7rem;
    }

    .portfolio {
        margin-bottom: 8rem;
    }

    .portfolio .portfolio-container{
        grid-template-columns: repeat(2, 1fr);
    }

    .footer{
        text-align: center ;
    }
}

@media (max-width: 617px) {
    .portfolio .portfolio-container{
        grid-template-columns: repeat(1, 1fr);
    }

    .hero{
        margin-left: 3rem;
    }

    
}
@media (min-width: 617px){
    .contact-component form .input-box input{
        max-width: 49%;
    }
}

@media (max-width: 400px){
    .hero{
        margin-top: 4rem;
    }

    .hero .img-container img{
        margin-top: 4rem;
        width: 70vw;
    }
    .navbar-container{
        padding: 1rem;
    }
    .navbar-logo{
        margin: 0 .5rem;
    }

}



.nav-active{
    transform: translateX(0%);
}
