*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    scroll-behavior: smooth;
}

:root{
    --bg-color: #2d2e32;
    --second-bg-color: #1e1f22;
    --text-color: #f5f5f5;
    --main-color: #61f5ab;
}

.about{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--second-bg-color);
    padding: 8rem 0 ;

}

#about{
    position: relative;
    top: -500px;
}


.about-img img{
    width: 35vw;
}

.about-content{
    width: 40%;
}

.heading{
    text-align: center;
    font-size: 4rem;
}

.about-content span{
    color: var(--main-color);
}

.about-content h2{
    text-align: left;
    line-height: 1.3;
}

.about-content h3{
    font-size: 2.3rem;
    margin-bottom: 10px;
}

.about-content p{
    font-size: 1.1rem;
    margin-bottom: 40px;
}

.read-more{
    background-color: var(--main-color);
    padding: 15px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bolder;
    color: var(--bg-color);
    transition: 0.4s ease-in-out;
    box-shadow: 0 0 1rem var(--main-color);
    cursor: pointer;
}

.read-more:hover{
    color: var(--text-color);
    box-shadow: none;

}