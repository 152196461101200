
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
}

:root{
    --bg-color: #2d2e32;
    --second-bg-color: #1e1f22;
    --text-color: #f5f5f5;
    --main-color: #61f5ab;
}

#portfolio{
    position: relative;
    top: -5.5rem;
}

.portfolio{
    background: var(--secong-bg-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}
.portfolio-heading{
    text-align: center;
    font-size: 4rem;
}
.portfolio h2{
    margin-bottom: 4rem;
}

.portfolio h2 span{
    color: var(--main-color);
}

.portfolio-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 3rem;
    justify-items: flex-end;

    
}

.portfolio-container .portfolio-box{
    position: relative;
    border-radius: 1rem;

    box-shadow: 0px 00px 30px black;
    overflow: hidden;
    display: flex;
    
}

.portfolio-box{
    max-width: 400px;
    transition: .5s ease;;
}

.portfolio-box img{
    width: 100%;
    transition: .5s ease;
}


.portfolio-box .portfolio-layer{
    color: var(--bg-color);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(45,46,50);
    background: linear-gradient(180deg, rgba(45,46,50,1) 0%, rgba(245,245,245,.5) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 2rem;
    transform: translateY(100%);
    transition: .5s ease;
}

.portfolio-box:hover{
    transform: scale(1.05);
    box-shadow: none;
}
.portfolio-box:hover .portfolio-layer{
    transform: translateY(0);
}


.portfolio-layer h4{
    font-size: 1.5rem;
    color: #f5f5f5;
    margin-bottom: 1rem;
}

.portfolio-layer p{
    font-size: 1rem;
    margin: .3rem 0 1rem;
    color: #f5f5f5;
}

.portfolio-links{
    display: flex;
    gap: 1rem;
}

.portfolio-links a{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background: var(--bg-color);
    border-radius: 50%;
}

.portfolio-links a i{
    font-size: 1rem;
    color: var(--text-color);
}