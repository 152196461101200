*{
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    
}

.hero{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
}

#home{
    position: relative;
    top: -1000px;
}

.hero-container{

    display: flex;
    height: 60vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-right: 50px;
}

.hero-container span{
    color: #2d2e32;
    background-color: #61f5ab;
    padding: 1px 10px;
    border-radius: 5px;
}

.tittle{ 

    font-size: 28px;
    font-weight: lighter;
    color: #f5f5f5;
}

.hero-container h4{
    font-size: 15px;
    color: #989898f5;
    letter-spacing: 1px;
    margin-top: 10px;

}


.cv{
    background-color: #1e1f22;
    padding: 15px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: bolder;
    color: #f5f5f5;
    margin-inline-end: 10px;
    transition: 0.4s ease-in-out;
    margin-top: 20px;
    margin-inline-start: -7px;
    box-shadow: 0 0 1rem #1e1f22;
}

.cv:hover{
    color: #61f5ab;
    box-shadow: none;

}

.lets-contact{
    color:#61f5ab;
    font-size: 20px;
}

.lets-contact:hover{
    text-decoration: underline;
}

.img-container img{
    width: 40vw;
    animation: floatImage 4s ease-in-out infinite;
}

@keyframes floatImage{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-1rem);
    }
    100%{
        transform: translateY(0);
    }
}
