*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    scroll-behavior: smooth;
}

:root{
    --bg-color: #2d2e32;
    --second-bg-color: #1e1f22;
    --text-color: #f5f5f5;
    --main-color: #61f5ab;
}

.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2rem 9%;
    background-color: var(--second-bg-color);
}

.footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-social a {
margin: 5px 10px 10px 10px;
}
.footer-social img {
    width: 30px;
}

.footer-text p{
    font-size: 1rem;
}