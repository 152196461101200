@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    scroll-behavior: smooth;
}

:root{
    --bg-color: #2d2e32;
    --second-bg-color: #1e1f22;
    --text-color: #f5f5f5;
    --main-color: #61f5ab;
}

#contact{
    position: relative;
    top: -100px;
}

.contact-heading{
    text-align: center;
    font-size: 4rem;
}

.contact-component h2{
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.contact-component h2 span{
    color: var(--main-color);
}
.contact-component form{
    max-width: 40rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact-component form .input-box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact-component form .input-box input,
.contact-component form textarea{
    width: 100%;
    padding: 1.5rem;
    font-size: 1rem;
    color: var(--text-color);
    background-color: var(--second-bg-color);
    border-radius: .8rem;
    margin: .7rem 0;
    font-family: 'Space Mono', monospace;

}

.contact-component form .input-box input{
    width: 100%;
}

.contact-component form textarea{
    resize: none;
}

.message-btn{
    background-color: var(--main-color);
    padding: 15px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bolder;
    color: var(--bg-color);
    transition: 0.4s ease-in-out;
    box-shadow: 0 0 1rem var(--main-color);
    cursor: pointer;
    margin-top: 1rem;
}

.message-btn:hover{
    color: var(--second-bg-color);
    box-shadow: none;

}